<template>
  <div class="content_container">

    <el-breadcrumb
      separator-class="el-icon-arrow-right "
      class="titleNav"
    >

      <el-breadcrumb-item>
        <span @click="$router.push('/member-complaint')">我的投诉 </span>
      </el-breadcrumb-item>
      <el-breadcrumb-item>投诉详情</el-breadcrumb-item>
    </el-breadcrumb>

    <el-form
      ref="ruleForm"
      label-width="140px"
      class="ruleForm"
    >

      <el-form-item label="订单编号">
        {{ruleForm.get_order.out_request_no}}
      </el-form-item>
      <!-- <el-form-item label="产品图">
        <img
          :src="$config.baseUrl+ruleForm.good_logo"
          style="width:200px"
        >

      </el-form-item>
      <el-form-item label="产品名称">
        {{ruleForm.goodname}}
      </el-form-item> -->
      <el-form-item label="投诉店铺">
        {{ruleForm.shopname}}
      </el-form-item>
      <el-form-item label="投诉类型">
        <span v-if="ruleForm.type==1"> 发货问题 </span>
        <span v-if="ruleForm.type==2"> 产品问题 </span>
        <span v-if="ruleForm.type==3"> 发票问题 </span>
        <span v-if="ruleForm.type==4"> 客服售后问题 </span>
      </el-form-item>
      <el-form-item label="投诉内容">
        {{ruleForm.mark}}
      </el-form-item>
      <el-form-item label="投诉图">
        <img
          v-for="(item, index) in ruleForm.pic.split(',')"
          :key="index"
          :src="item"
          alt=""
          style="width: 100px; height: 100px; margin-right: 10px"
          @click="handlePictureCardPreview0"
        >
        <el-dialog
          :visible.sync="dialogVisible0"
          style="width:100%"
        >
          <img
            style="width:100%;height:500px "
            :src="dialogImageUrl0"
            alt=""
          >
        </el-dialog>
      </el-form-item>
      <el-form-item label="投诉时间">
        {{new Date(ruleForm.add_date).format('yyyy-MM-dd')}}
      </el-form-item>
      <el-form-item label="商家回应">

        <el-tag
          v-if="ruleForm.shop_status==1"
          type="success"
        >
          同意
        </el-tag>
        <el-tag
          v-if="ruleForm.shop_status==2"
          type="danger"
        >
          不同意
        </el-tag>
        <el-tag
          v-if="ruleForm.shop_status==0"
          type="warning"
        >
          待回复
        </el-tag>
        <p   v-if='ruleForm.reply'>原因：{{ruleForm.reply}}</p>

      </el-form-item>
      <el-form-item label="仪商城审判">

        <el-tag
          v-if="ruleForm.admin_status==1"
          type="success"
        >
          投诉成立
        </el-tag>
        <el-tag
          v-if="ruleForm.admin_status==2"
          type="danger"
        >
          投诉不成立
        </el-tag>
        <el-tag
          v-if="ruleForm.admin_status==0"
          type="warning"
        >
          审判中
        </el-tag>

      </el-form-item>
      <el-form-item
        v-if='ruleForm.admin_mark'
        label="仪商城审判结词"
      >

        <span v-html="ruleForm.admin_mark" />
      </el-form-item>
      <el-form-item>

        <el-button
          style="margin-left:300px;width:100px; margin-top:30px"
          type="primary"
          @click="$router.go(-1)"
        >返回</el-button>
      </el-form-item>

    </el-form>
  </div>

</template>

<script type="text/javascript">
// import this.$config from '../../../this.$config/this.$config'
export default {
  data() {
    return {
      ruleForm: {
        get_order:{}
      },
        dialogImageUrl0: "",
      dialogVisible0: false,
    };
  },
  filters: {},
  methods: {
      handlePictureCardPreview0(file) {
      this.dialogImageUrl0 = file.target.src;
      this.dialogVisible0 = true;
    },
    getSeek() {
      this.$get("seller/goodcomplain/" + this.$route.query.id).then(res => {
        this.ruleForm = res.result;
      });
    }
  },
  components: {},
  created() {
    this.getSeek();
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/mixins";
// ::v-deep .el-form-item__label{ margin-right: 20px;
//   background: #eee;
//   display: block;height: 100%;
// }
::v-deep .el-form-item {
  margin-bottom: 2px;
}

.ruleForm {
  background: #fff;
  padding: 10px;
}
.titleNav {
  width: 100%;
  padding: 15px;
  margin-bottom: 10px;
  background: #fff;
}
</style>
